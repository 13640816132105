<template>
  <div class="container">
    <SearchTop></SearchTop>
    <div class="wapper flex w1200" style="min-height: calc(100vh - 313px)">
      <LeftMenu @leftsearch="handleLeftSearch" :leftdata="leftData" style="margin-top: 30px"></LeftMenu>
      <div class="rightPage">
        <!-- 图书基本信息 -->
        <div class="book-base-content">
          <div class="img-content">
            <img class="cover" :src="getCover(bookInfo.cover)" />
            <img @click="coverVisible = true" class="showBig" src="@/assets/images/icon-fd.png" />
          </div>
          <div class="book-content">
            <ul>
              <li class="book-title" v-if="bookInfo.title">
                {{ bookInfo.title }}
              </li>
              <li class="book-info" v-if="bookInfo.author">作者：{{ bookInfo.author }}</li>
              <li class="book-info" v-if="bookInfo.isbn">ISBN：{{ bookInfo.isbn }}</li>
              <li class="book-info">&nbsp;出版单位：化学工业出版社</li>
              <li class="book-info" v-if="bookInfo.publishDateStr">出版日期：{{ bookInfo.publishDateStr }}</li>
              <li class="book-info" v-if="bookInfo.subjects">学科分类：{{ bookInfo.subjects.join(";") }}</li>
              <li
                class="online-read"
                v-if="bookInfo.archive != null"
                :style="{
                  cursor: bookInfo.archive != null ? 'pointer' : 'not-allowed',
                }"
                @click="handleReadOnline(bookInfo.archive)"
                @mouseenter="imgSrc = zxydSelect"
                @mouseleave="imgSrc = zxydDefault"
              >
                <img :src="imgSrc" />
              </li>
            </ul>
          </div>
        </div>

        <nav class="nav-content-bar">
          <ul>
            <a @click.prevent="anchor('kzxx')"><li :class="{ actived: activeNav == 'kzxx' }" @click="handleClickNav('kzxx')">扩展信息</li></a>
            <a v-if="bookInfo.content != '' && bookInfo.content != null" @click.prevent="anchor('nrjj')"><li :class="{ actived: activeNav == 'nrjj' }" @click="handleClickNav('nrjj')">内容简介</li></a>
            <a v-if="bookInfo.catalog != '' && bookInfo.catalog != null" @click.prevent="anchor('ml')"><li :class="{ actived: activeNav == 'ml' }" @click="handleClickNav('ml')">目录</li></a>
          </ul>
        </nav>
        <div id="kzxx"></div>
        <ul class="nav-detail-content">
          <li class="nav-detail-item">
            <div class="header">
              <div></div>
              <span>扩展信息</span>
            </div>
            <ul>
              <li class="baseSpanStyle" v-if="bookInfo.clcNo">中图法分类号：{{ bookInfo.clcNo }}</li>
              <li class="baseSpanStyle" v-if="bookInfo.clcs">中图法分类： {{ bookInfo.clcs.join(";") }}</li>
              <!-- <li class="baseSpanStyle" v-if="bookInfo.price">
                定价：{{ bookInfo.price }}
              </li> -->
              <li class="baseSpanStyle" v-if="bookInfo.revision">版次：{{ bookInfo.revision }}</li>
              <li class="baseSpanStyle" v-if="bookInfo.impression">印次：{{ bookInfo.impression }}</li>
              <li class="baseSpanStyle" v-if="bookInfo.pages">页数：{{ bookInfo.pages }}</li>
              <li class="baseSpanStyle" v-if="bookInfo.words">字数：{{ bookInfo.words }}千字</li>
            </ul>
            <div id="nrjj"></div>
          </li>
          <li v-if="bookInfo.content != '' && bookInfo.content != null" class="nav-detail-item" id="nrjj">
            <div class="header">
              <div></div>
              <span>内容简介</span>
            </div>
            <ul>
              <li class="baseSpanStyle">
                {{ bookInfo.content }}
              </li>
            </ul>
            <div id="ml"></div>
          </li>
          <li v-if="bookInfo.catalog != '' && bookInfo.catalog != null" class="nav-detail-item">
            <div class="header">
              <div></div>
              <span>目录</span>
            </div>
            <ul>
              <li class="catelog-span" v-for="(item, index) in tableData.slice((queryForm.pageNum - 1) * queryForm.pageSize, queryForm.pageNum * queryForm.pageSize)" :key="index">
                {{ item }}
              </li>
            </ul>
            <!-- <span v-html="bookInfo.catalog">
                            
                        </span> -->
            <el-pagination v-if="tableData.length > 0" @current-change="handleCurrentChange" :page-size="queryForm.pageSize" :current-page="queryForm.pageNum" background layout="prev, next" :total="tableData.length"> </el-pagination>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog append-to-body top="150px" :visible.sync="coverVisible">
      <div class="big-detail">
        <img :src="getCover(bookInfo.cover)" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SearchTop from "@/components/searchTop";
import { getDetail, searchLeft, recordReadLog } from "@/api/home";
import LeftMenu from "@/components/leftmenu";
import zxydDefault from "@/assets/images/icon-yuedu-default.png";
import zxydSelect from "@/assets/images/icon-yuedu-select.png";
import baseUrl from "@/api/baseurl.js";
import zanwuz from "@/assets/images/zwfm-z.png";
import zanwud from "@/assets/images/zwfm-d.png";
import { setToken, getToken } from "../../utils/auth";
export default {
  data() {
    return {
      leftData: [],
      zanwuz,
      zanwud,
      baseUrl: baseUrl.baseUrl,
      readUrl: baseUrl.readUrl,
      activeNav: "kzxx",
      coverVisible: false,
      zxydDefault,
      zxydSelect,
      imgSrc: zxydDefault,
      bookInfo: {},
      tableData: [],
      queryForm: {
        pageSize: 20,
        pageNum: 1,
      },
    };
  },
  components: {
    SearchTop,
    LeftMenu,
  },
  created() {
    this.$store.dispatch("resetField");
    let query = this.$route.query;
    if (query.id) {
      this.bookId = query.id;
      this.getDetail();
    }
    this.getLeftData();
  },
  mounted() {},
  methods: {
    // 访问日志
    recordReadLog() {
      let postBody = {
        dataId: this.bookInfo.id,
        title: this.bookInfo.title,
        isbn: this.bookInfo.isbn,
        type: 10,
      };
      // recordReadLog(postBody).then(res=>{

      // }).catch(error=>{

      // })
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
    },
    handleReadOnline(val) {
      if (val != null) {
        if (val.fileState == 1) {
          //已经拆分了，调用新版阅读器
          let cToken = getToken();
          if (cToken != null && cToken.length > 0) {
            //编码 防止有特殊字符
            cToken = encodeURIComponent(cToken);
          }
          let href = this.readUrl + "?resourceId=" + this.bookInfo.id + "&token=" + cToken;
          window.open(href, "_blank");
        } else {
          let routerUrl = this.$router.resolve({
            path: "/pdf",
            query: {
              dataId: this.bookInfo.id,
              archiveSid: val.sid,
            },
          });
          window.open(routerUrl.href, "_blank");
        }

        // this.$router.push({
        //   path: "/pdf",
        //   query: {
        //     dataId: this.bookInfo.id,
        //     archiveSid: val.sid,
        //   },
        // });
      }
    },
    handleLeftSearch(type, value, names, yearIndex, yearMore, addIndex) {
      if (type == "publishYear") {
        this.$store.dispatch("setLeft", { publishYear: names });
        this.$store.dispatch("updateField", {
          key: "yearIndex",
          value: yearIndex,
        });
        this.$store.dispatch("updateField", {
          key: "yearMore",
          value: yearMore,
        });
        this.$store.dispatch("updateField", {
          key: "addIndex",
          value: addIndex,
        });
        this.$router.push({
          path: "/classify",
        });
      } else if (type == "subjectIds" || type == "clcIds") {
        this.$store.dispatch("updateField", {
          key: type,
          value: [value.id],
        });
        this.$store.dispatch("setLeft", { [type]: names });
        this.$router.push({
          path: "/classify",
        });
      }
    },
    // 获取左侧聚合数据
    getLeftData() {
      let postBody = {
        searchWord: "", //检索词
        searchBarWord: "", //检索条-检索词 字段默认为题名、作者、ISBN
        searchField: "title,author", //检索字段，先固定值
        subjectIds: [], //左侧学科
        clcIds: [], //左侧中图法
        publishYear: null, //出版年
        pageNum: 1, //页码 1
        pageSize: 10, //页面大小 10
        orderByColumn: "state_time", //排序字段 默认出版日期
        isAsc: "desc", //排序方式 默降序排序
      };
      searchLeft(postBody).then(res => {
        this.leftData = res.data;
      });
    },
    getCover(id) {
      if (id != undefined && id != null && id != "") {
        return this.baseUrl + id;
      } else {
        return zanwud;
      }
    },
    getDetail() {
      getDetail(this.bookId)
        .then(res => {
          if (res.data.state == "OUT_SHELVE") {
            this.$message.warning("该图书已下架，无法访问");
            this.$router.push("/index");
            return;
          }
          this.bookInfo = res.data;
          this.recordReadLog();
          if (res.data.catalog != undefined && res.data.catalog != null) {
            // let str = res.data.catalog;
            // let reg = new RegExp(/<p>([^(<\/p>)].*?)<\/p>/g);
            // let arr;
            // let result = [];
            // while((arr = reg.exec(str))!==null){
            //     result.push(arr[1]);
            // }
            // this.tableData=result
            let str = res.data.catalog;
            let result = str.split("\n");
            this.tableData = result;
          }
        })
        .catch(error => {
          this.$router.push("/index");
        });
    },
    handleClickNav(val) {
      this.activeNav = val;
    },
    /*锚链接跳转*/
    anchor(anchorName) {
      /*找到锚点*/
      let anchorElement = document.getElementById(anchorName);
      /*如果对应id的锚点存在，就跳转到锚点*/
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__headerbtn {
  position: absolute;
  top: -6px;
  right: -47px;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 32px;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}
::v-deep .el-dialog__header {
  padding: 0;
  padding-bottom: 0px;
}
::v-deep .el-dialog__body {
  padding: 0px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
::v-deep .el-pagination {
  margin-top: 20px;
  text-align: end;
}
.baseSpanStyle {
  word-break: break-all;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #666666;
  line-height: 28px;
  white-space: pre-wrap;
}
::v-deep .el-dialog {
  width: 700px;
}
.big-detail {
  position: relative;
  height: 700px;
  width: 700px;
  // border: 1px solid #e1e1e1;
  text-align: center;
  vertical-align: middle;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  img {
    cursor: pointer;
    display: inline-block;
    max-width: 698px;
    max-height: 698px;
    width: auto;
    height: 100%;
  }
}
.container {
  width: 100%;
  background: #fff;
  .wapper {
    .rightPage {
      padding: 30px 30px;
      flex: 1;
      position: relative;
      .book-base-content {
        display: flex;
        word-break: break-all;
        .img-content {
          position: relative;
          height: 240px;
          width: 240px;
          border: 1px solid #e1e1e1;
          text-align: center;
          vertical-align: middle;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-pack: center;
          justify-content: center;
          overflow: hidden;
          margin-right: 30px;
          .cover {
            display: inline-block;
            max-width: 238px;
            max-height: 238px;
            width: auto;
            height: auto;
          }
          .showBig {
            position: absolute;
            bottom: 0;
            right: 0;
            cursor: pointer;
          }
        }
        .book-content {
          width: 600px;
          .book-title {
            font-size: 24px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
            line-height: 28px;
            margin-bottom: 15px;
            // cursor: pointer;
          }
          .book-info {
            // word-break: break-all;
            // font-size: 16px;
            // font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            // font-weight: 400;
            // text-align: left;
            // color: #666666;
            // line-height: 28px;
            @extend .baseSpanStyle;
          }
          .online-read {
            width: 130px;
            height: 39px;
            background: #078bef;
            border-radius: 6px;
            margin-top: 10px;
            cursor: pointer;
          }
        }
      }
      .nav-content-bar {
        position: sticky;
        position: -webkit-sticky; // 兼容 -webkit 内核的浏览器
        top: 1px;
        width: auto;
        height: 38px;
        background: #f7f7f7;
        margin-top: 30px;
        padding: 0 40px;
        box-sizing: border-box;
        ul {
          li {
            display: inline-block;
            width: 104px;
            height: 38px;
            font-size: 16px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: center;
            color: #333333;
            line-height: 38px;
            cursor: pointer;
            &:hover {
              background: #078bef;
              color: #ffffff;
            }
          }
          .actived {
            background: #078bef;
            color: #ffffff;
          }
        }
      }
      .nav-detail-content {
        .nav-detail-item {
          margin: 30px 0;
          .header {
            margin-bottom: 10px;
            div {
              display: inline-block;
              width: 5px;
              height: 16px;
              background: #078bef;
              margin-right: 14px;
              vertical-align: text-top;
              margin-top: 3px;
            }
            span {
              display: inline-block;
              font-size: 16px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: bold;
              text-align: left;
              color: #333333;
              line-height: 16px;
            }
          }
          .catelog-span {
            font-size: 16px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
            line-height: 48px;
            border-bottom: 1px dashed #e6e6e6;
            padding-left: 15px;
          }
        }
      }
    }
  }
}
</style>
